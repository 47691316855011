import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/layout';
import { FiArrowRight } from 'react-icons/fi';
import './index.scss';

import homeImg from '../images/home-bg.svg';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="home-middle">
      <div className="middle-context">
        <div className="middle-title">
          <h3>{data.profile.biography.biography}</h3>
        </div>
        <Link to="/about" className="about-link link">
          ABOUT ME <FiArrowRight />
        </Link>
      </div>
      <div className="middle-img">
        <img src={homeImg} className="home-img" alt="Homepage Image" />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
export const query = graphql`
  {
    profile: contentfulAuthor {
      name
      githubHandle
      instagramHandle
      linkedinHandle
      biography {
        biography
      }
      aboutMe {
        aboutMe
      }
      profilePhoto {
        file {
          url
        }
      }
    }
  }
`;
